@tailwind base;
@tailwind components;
@tailwind utilities;

.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
  }
  
  @media (min-width: 768px) {
    .form-container {
      max-width: 500px;
    }
  }
  
  @media (min-width: 1024px) {
    .form-container {
      max-width: 500px;
    }
  }